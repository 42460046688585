@use "~@latte-ui/css/src/api" as *;

#main-footer
{
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    display: block;
    z-index:0;

    &-menu
    {
        a.nav-link
        {
            font-family: $font-family-serif, serif;
            font-size:1.1rem;
            font-weight:300;
            font-style: italic;
            text-transform: lowercase;
            margin:0 #{gutter-get(3)};
            transition: all 360ms;

            &:hover, &:focus-within, &:active
            {
                color:#{color-no-variable(base-light, .5)};
            }
        }
    }
    &-logo
    {
        &-img
        {
            height:140px;
        }
        &-statement-img
        {
            height:40px;
            margin-top:130px;
        }
    }
    &-socials
    {
        a
        {
            font-family: $font-family-serif, serif;
            font-size:1.1rem;
            font-weight:300;
            font-style: italic;
            margin:0 #{gutter-get(5)};
            text-decoration: none;
            transition: all 360ms;

            svg {
                display: block;
                height:20px;
                color:#{color-no-variable(base-light)};
                transition: all 360ms;
            }

            &.social-phone
            {
                margin-left:0;

                .icon
                {
                    width:27px;
                    height:27px;
                    background:#{color-no-variable(base-light)};
                    border-radius: 50%;
                    margin-right:#{gutter-get(3)};
                    transition: all 360ms;

                    svg {
                        display: block;
                        height:13px;
                        color:#{color-no-variable(secondary)};
                    }
                }
            }

            &:hover, &:focus-within, &:active
            {
                svg {
                    color:#{color-no-variable(base-light, .5)};
                }

                &.social-phone
                {
                    color:#{color-no-variable(base-light, .5)};

                    .icon
                    {
                        background:#{color-no-variable(base-light, .5)};
                    }
                }
            }
        }
    }

    #copyright-footer
    {
        position: relative;
        display: block;

        small
        {
            font-family: $font-family-serif, serif;
            font-size:.9rem;
            font-weight:300;
            font-style: italic;
            color: #{color-no-variable(base-light)};

            a {
                font-size:0.9rem;
            }
        }
    }
}

@include responsive-up(md)
{
    #main-footer
    {
        background-attachment: fixed;

        &-menu
        {
            a.nav-link
            {
                margin: 0 #{gutter-get(2)};
            }
        }
        &-logo
        {
            &-statement-img
            {
                margin-top: #{gutter-get(10)};
            }
        }
        &-socials
        {
            a
            {
                margin: 0 #{gutter-get(1)};
            }
        }
    }
}
@include responsive-up(lg)
{
    #main-footer
    {
        &-menu
        {
            a.nav-link
            {
                font-size:1.2rem;
                margin: 0 #{gutter-get(3)};
            }
        }
        &-logo
        {
            &-statement-img
            {
                margin-top: 130px;
            }
        }
        &-socials
        {
            a
            {
                margin: 0 #{gutter-get(5)};
            }
        }
    }
}
