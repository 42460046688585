@use "~@latte-ui/css/src/api" as *;

#main-slider
{
    position:relative;

    &-gallery
    {
        height:60vh;
        position:relative;
        z-index:1;

        .image
        {
            width:100%;
            height:60vh;
            background-size:cover;
            background-repeat: no-repeat;
            background-position: center center;

            @at-root body.home &
            {
                height:100vh;
            }
        }

        @at-root body.home &
        {
            height:100vh;
        }
    }
    &-overlay
    {
        background:#{color-no-variable(secondary, .55)};
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:2;

        .col-12
        {
            position: relative;
        }

        @at-root body.home &
        {
            background:#{color-no-variable(secondary, .33)};
        }
    }
    &-content
    {
        height: calc(60vh - 190px);

        h2
        {
            font-family: $font-family-serif, serif;
            font-style: italic;
        }
    }
    &-nav
    {
        height: 40px;
        pointer-events: none;
        position: relative;
        z-index:1;

        .dot
        {
            width:9px;
            height:9px;
            background:transparent;
            border:solid 2px #{color-no-variable(base-light)};
            margin:8px #{gutter-get(1)} 0;
            cursor: pointer;
            transition: all 360ms;
            outline:0;
            box-shadow: none;
            pointer-events: auto;

            &.tns-nav-active
            {
                background: #{color-no-variable(base-light)};
            }
        }
    }
    &-caption
    {
        height:auto;
        position: absolute;
        left:15px;
        right:15px;
        bottom:50px;

        .caption
        {
            font-size:.78rem;
            font-weight: 900;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            color:#{color-no-variable(base-light)};
            line-height: 1.2;
        }
    }
}
@include responsive-up(sm)
{
    #main-slider
    {
        &-content
        {
            height: calc(60vh - 200px);
        }
    }
}
@include responsive-up(md)
{
    #main-slider
    {
        &-gallery
        {
            .image
            {
                background-attachment: fixed;
            }
        }

        &-content
        {
            height: calc(60vh - 300px);
        }
        &-nav
        {
            height: 50px;
        }
        &-caption
        {
            bottom:60px;
        }
    }
}
@include responsive-up(lg)
{
    #main-slider
    {
        &-nav
        {
            height: 60px;
        }
        &-caption
        {
            bottom:40px;

            .caption
            {
                text-align: right;
            }
        }
    }
}
