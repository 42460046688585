@use "~@latte-ui/css/src/api" as *;

.btn, .wp-block-buttons > .wp-block-button, .wp-block-buttons > .wp-block-button > a, .wp-block-buttons > .wp-block-button > .wp-block-button__link
{
    font-size:1rem;
    font-weight:700;
    line-height: 1;
    text-decoration: none;
    display:table;
    border-radius:0;
    padding:#{gutter-get(4)};
    margin:0 0 10px 0;
    transition: all 360ms;
    border:0 !important;
    outline:0 !important;
    box-shadow: none;

    &.btn-white, &.btn-white > a, &.btn-white > .wp-block-button__link
    {
        background: #{color-no-variable(base-light)};
        color: #{color-no-variable(secondary)};
        text-transform: uppercase;
    }
    &.btn-text, &.btn-text > a, &.btn-text > .wp-block-button__link
    {
        background: transparent;
        font-family: $font-family-serif, sans-serif;
        font-size:1.1rem;
        font-weight:300;
        font-style: italic;
        color: #{color-no-variable(base-light)};
        padding:0 #{gutter-get(4)} #{gutter-get(1\/2)} #{gutter-get(6)};

        &::before
        {
            content: url(../icons/light/angle-right-white.svg);
            display:block;
            width:12px;
            position:absolute;
            top:50%;
            left:0;
            transform:translateY(-50%);
            transition:all 360ms;
        }
        &.arrow-left
        {
            &::before
            {
                content: url(../icons/light/angle-left-white.svg);
            }
        }
    }

    &:hover, &:focus-within, &:active, &:not(.disabled):active
    {
        border:0 !important;
        outline:0 !important;
        box-shadow: none !important;

        &.btn-white, &.btn-white > a, &.btn-white > .wp-block-button__link
        {
            background: #{color-no-variable(base-light, .85)};
            color: #{color-no-variable(secondary)};
        }
        &.btn-text, &.btn-text > a, &.btn-text > .wp-block-button__link
        {
            background: transparent;
            font-family: $font-family-serif, sans-serif;
            color: #{color-no-variable(base-light, .85)};

            &::before
            {
                left:7px;
            }

            &.arrow-left
            {
                &::before
                {
                    left:0px;
                }
            }
        }
    }

    @include responsive-down(sm)
    {
        margin-left:auto !important;
        margin-right:auto !important;
    }

    @include responsive-up(sm)
    {
        display:inline-block;
        margin:0 5px 10px 0;

        &:last-child
        {
            margin-right:0;
        }
    }
    @include responsive-up(md)
    {
        font-size:1.1rem;
        padding:#{gutter-get(5)} 20px;
        margin:0 10px 10px 0;

        &.btn-text, &.btn-text > a, &.btn-text > .wp-block-button__link
        {
            font-size: 1.2rem;
        }
    }
    @include responsive-up(lg)
    {
        margin:0 20px 10px 0;
    }
}

.wp-block-buttons
{
    margin:1.5rem 0 0;

    &:last-child
    {
        margin-bottom:0;
    }
}
.wp-block-buttons .wp-block-button
{
    background-color:transparent !important;
    padding:0 !important;

    &.btn-text
    {
        &::before
        {
            content: '';
            display: none;
        }
        &.arrow-left
        {
            &::before
            {
                &::before
                {
                    content: '';
                    display: none;
                }
            }
        }
    }

    a, .wp-block-button__link
    {
        margin:0 !important;
    }
}
