@use "~@latte-ui/css/src/api" as *;

label {
	font-size:1rem !important;
	font-weight:300 !important;
	text-align: left;
}
input, textarea, select, .form-control, .nf-form-content .listselect-wrap .nf-field-element div {
	width: 100%;
	height: 50px;
	font-size: 1rem;
	color: #{color-no-variable(primary)};
	background-color: #{color-no-variable(base-light)};
	border: solid 1px #{color-no-variable(secondary)};
	border-radius:0 !important;
	box-shadow: none;
	transition: all 200ms;
	outline: 0;
	padding: 5px;

	&:hover, &:focus-within, &:active {
		border: solid 1px #{color-no-variable(primary)};
		box-shadow: none;
		outline: 0;
	}

	@include responsive-up(lg) {
		padding: 5px 10px;
	}
}

input[type="checkbox"] {
	width:auto;
	height:auto;
	margin-right:5px;
}

input[type="button"], input[type="submit"] {
    cursor:pointer;
}

.nf-before-form-content {
	margin-bottom:1rem;
}
.nf-form-content {
	width:100%;
	padding:0 !important;
	margin:0 !important;
}

@include responsive-down(sm)
{
    #ninja_forms_required_items, .nf-field-container
    {
        margin-bottom:10px !important;
    }
    .label-above .nf-field-label
    {
        margin-bottom:5px !important;
    }
}
