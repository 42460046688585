@use "~@latte-ui/css/src/api" as *;

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.has-large-font-size, .has-huge-font-size
{
    font-family: $font-family-serif, sans-serif;
    display: block;
    color: inherit;
    line-height: 1.25;
    word-break: break-word;
    position: relative;
    margin-top:#{gutter-get(4)} !important;
    margin-bottom:#{gutter-get(4)} !important;

    &:first-child
    {
        margin-top:0 !important;
    }

    + h1, + h2, + h3, + h4, + h5, + h6,
    + .h1, + .h2, + .h3, + .h4, + .h5, + .h6,
    + .has-large-font-size, + .has-huge-font-size
    {
        margin-top:-.5rem !important;
    }
}

h1, .h1, .has-huge-font-size
{
    font-size:35px;
    font-weight: 300;
    color:#{color-no-variable(third)};
}

h2, .h2, .has-large-font-size
{
    font-size:30px;
    font-weight: 300;
}

h3, .h3
{
    font-size:27px;
    font-weight: 300;
}

h4, .h4
{
    font-size:25px;
    font-weight: 300;
}
h5, .h5 {
    font-size:22px;
    font-weight: 600;
    color:#{color-no-variable(third)};
}
h6, .h6
{
    font-size:18px;
    font-weight: 600;
}

.lead, .intro, .has-medium-font-size
{
    font-family: $font-family, sans-serif;
    font-size: 20px;
    font-weight:400;
    line-height: 1.4;
    color: inherit;
    display: block;
    margin-bottom:#{gutter-get(4)} !important;

    a
    {
        font-size: 20px;
    }
}

small, .small, .has-small-font-size
{
    font-family: $font-family, sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: inherit;
    display: block;
    margin-bottom:#{gutter-get(4)} !important;

    a
    {
        font-size: 14px;
    }
}

p, .has-normal-font-size
{
    font-family: $font-family, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5em;
    color:inherit;
    word-break: break-word;
    position: relative;
    display: block;
    margin-bottom:#{gutter-get(4)} !important;

    &:last-child
    {
        margin-bottom: 0 !important;
    }
}

a, p a, .has-small-font-size a, .has-small-font-size p a
{
    color: currentColor;
    text-decoration: underline;

    &:hover, &:focus-within
    {
        color: currentColor;
        text-decoration: none;
    }
}

.utopia
{
    font-family: $font-family-serif, serif;
    font-style: italic;
    line-height: 1;
}
@include responsive-up(sm)
{
    p, .has-small-font-size
    {
        line-height: 1.9em;
    }
}
@include responsive-up(lg)
{
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6,
    .has-large-font-size, .has-huge-font-size
    {
        margin-top:#{gutter-get(7)} !important;
        margin-bottom:#{gutter-get(6)} !important;

        + h1, + h2, + h3, + h4, + h5, + h6,
        + .h1, + .h2, + .h3, + .h4, + .h5, + .h6,
        + .has-large-font-size, + .has-huge-font-size
        {
            margin-top:-#{gutter-get(6)} !important;
        }
    }

    h1, .h1, .has-huge-font-size
    {
        font-size:50px;
    }
    h2, .h2, .has-large-font-size
    {
        font-size:40px;
    }
    h3, .h3
    {
        font-size:37px;
    }
    h4, .h4
    {
        font-size:34px;
    }
    h5, .h5
    {
        font-size:30px;
    }
    h6, .h6
    {
        font-size:26px;
    }

    small, .small
    {
        margin-bottom:#{gutter-get(4)} !important;
    }
}
