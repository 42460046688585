@use "~@latte-ui/css/src/api" as *;

#main-content
{
    #projects
    {
        &-filter
        {
            width:100%;
            position: relative;
            margin-bottom:#{gutter-get(4)};

            &-label
            {
                flex:0 0 100%;
                font-family: $font-family-serif, serif;
                font-size:1rem;
                font-style: italic;
                line-height: 1.25;
                color: #{color-no-variable(primary)};
                background: #{color-no-variable(base-light)};
                position: relative;
                cursor: pointer;
                padding:7.5px 15px;
                transition: all 360ms;

                span.arrow
                {
                    width:auto;
                    height:24px;
                    vertical-align: middle;
                    margin-left:#{gutter-get(2)};
                    position: relative;

                    &::before
                    {
                        content:"";
                        display: none;
                    }

                    svg
                    {
                        height:24px;
                        transform:rotate(0);
                        transition: all 360ms;
                    }
                }
            }
            &-input
            {
                width:118px;
                height:39px;
                cursor: pointer;
                padding:0;
                margin:0;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 3;

                &:hover
                {
                    & + label
                    {
                        color: #{color-no-variable(secondary)};
                    }
                }

                &:checked
                {
                    & + label
                    {
                        color: #{color-no-variable(secondary)};

                        span.arrow
                        {
                            svg
                            {
                                transform:rotate(180deg);
                            }
                        }

                        + ul.filter
                        {
                            opacity:1;
                            visibility: visible;
                            top:38px;
                        }
                    }
                }
            }

            .filter
            {
                background:#{color-no-variable(base-light)};
                padding:7.5px #{gutter-get(4)} #{gutter-get(4)};
                opacity:0;
                visibility: hidden;
                transition: all 360ms;
                position: absolute;
                top:0;
                left:0;
                right:0;
                z-index:3;

                &-item
                {
                    width:100%;
                    font-family: $font-family-serif, serif;
                    font-size:1rem;
                    font-style: italic;
                    line-height: 1.25;
                    padding:0;
                    margin:7.5px 0 0;
                    position: relative;

                    label
                    {
                        display:block;
                        color:#{color-no-variable(primary)};
                        text-align: center;
                        background:transparent;
                        border:solid 1px #{color-no-variable(primary)};
                        padding:7.5px 15px;
                        transition: all 360ms;
                    }
                    input
                    {
                        width:100%;
                        height:100%;
                        cursor: pointer;
                        padding:0;
                        margin:0;
                        position: absolute;
                        top: 0;
                        bottom:0;
                        left: 0;
                        right:0;
                        opacity: 0;
                        z-index: 3;

                        &:hover
                        {
                            & + label
                            {
                                background: #{color-no-variable(primary)};
                                color:#{color-no-variable(base-light)};
                            }
                        }

                        &:checked~label
                        {
                            background: #{color-no-variable(primary)};
                            color:#{color-no-variable(base-light)};
                        }
                    }

                    &:last-child
                    {
                        margin-right:0;
                    }
                }
            }
        }

        .project
        {
            width:100%;
            margin-top:#{gutter-get(4)};
            overflow: hidden;
            position: relative;

            img
            {
                width:100%;
                height:275px;
                object-fit: cover;
                object-position: center;
                transform:scale(1);
                transition: all 360ms;
            }

            .title
            {
                pointer-events: none;
                display: flex;
                justify-content: center;
                align-items: center;
                width:100%;
                background:#{color-no-variable(secondary, .77)};
                background: -moz-linear-gradient(top, transparent 0%, #{color-no-variable(secondary, .77)} 100%);
                background: -webkit-linear-gradient(top, transparent 0%,#{color-no-variable(secondary, .77)} 100%);
                background: linear-gradient(to bottom, transparent 0%,#{color-no-variable(secondary, .77)} 100%);
                font-family: $font-family-serif, serif;
                font-size:22px;
                font-style: italic;
                line-height: 1.25;
                text-align: center;
                padding:#{gutter-get(2)};
                position: absolute;
                right:0;
                bottom:0;
                left:0;
                transition: all 360ms;
            }
        }
    }
}

@include responsive-up(sm)
 {
     #main-content
     {
         #projects
         {
             &-filter
             {
                 margin-bottom:0;

                 .filter
                 {
                     padding:7.5px #{gutter-get(4)};

                     &-item
                     {
                         width:auto;
                         margin:7.5px 15px 7.5px 0;
                     }
                 }
             }
             .project
             {
                 margin-top:#{gutter-get(7)};

                 img
                 {
                     height:240px;
                 }
             }
         }
     }
 }
@include responsive-up(md)
{
    #main-content
    {
        #projects
        {
            &-filter
            {
                &-label
                {
                    flex:0 0 auto;
                    color: #{color-no-variable(base-light)};
                    cursor: default !important;
                    background: transparent;
                    padding:0;
                    margin-right:15px;
                    position: relative;
                }
                &-input
                {
                    display: none;

                    &:checked
                    {
                        & + label
                        {
                            color: #{color-no-variable(base-light)};

                            span.arrow
                            {
                                svg
                                {
                                    transform:rotate(0);
                                }
                            }

                            + ul.filter
                            {
                                opacity:1;
                                visibility: visible;
                                top:unset;
                            }
                        }
                    }
                }

                .filter
                {
                    background:transparent;
                    padding:0;
                    opacity:1;
                    visibility: visible;
                    position: relative;
                    top:unset;
                    left:unset;
                    right:unset;

                    &-item
                    {
                        label
                        {
                            color: #{color-no-variable(base-light)};
                            border: solid 1px #{color-no-variable(base-light)};
                        }
                        input
                        {
                            &:hover
                            {
                                & + label
                                {
                                    background: #{color-no-variable(base-light)};
                                    color:#{color-no-variable(primary)};
                                }
                            }

                            &:checked
                            {
                                & + label
                                {
                                    background: #{color-no-variable(base-light)};
                                    color:#{color-no-variable(primary)};
                                }
                            }
                        }
                    }
                }
            }

            .project
            {
                img
                {
                    height:210px;
                }
            }
        }
    }
}
@include responsive-up(lg)
{
    #main-content
    {
        #projects
        {
            .project
            {
                img
                {
                    height:290px;
                }

                .title
                {
                    height:100%;
                    background:#{color-no-variable(secondary, .77)};
                    font-size:30px;
                    line-height: 1.25;
                    padding:#{gutter-get(7)};
                    top:0;
                    transform: scale(1.1);
                    opacity:0;
                    visibility: hidden;
                }

                &:hover, &:focus-within, &:active
                {
                    img
                    {
                        transform:scale(1.1);
                    }

                    .title
                    {
                        opacity:1;
                        visibility: visible;
                        transform: scale(1);
                    }
                }
            }
        }
    }
}
@include responsive-up(xl)
{
    #main-content
    {
        #projects
        {
            .project
            {
                img
                {
                    height:350px;
                }
            }
        }
    }
}
