@use "~@latte-ui/css/src/api" as *;

.feed-wrapper
{
    a.h5
    {
        font-weight: 300;
        text-decoration: none;
    }

    #instafeed
    {
        height:auto;
        display: flex;
        flex-flow: row nowrap;
        min-width: 100%;
        overflow:auto;
        padding-bottom: 24px;
        position: relative;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        gap:15px;

        &::-webkit-scrollbar {
            height: 9px;
        }

        &::-webkit-scrollbar-button,
        &::-webkit-scrollbar-corner {
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: #{color-no-variable(base-light, .15)};
            margin-left: 0;
            margin-right: 0;
            border-radius: 9px;
        }
        &::-webkit-scrollbar-thumb {
            width: 60px;
            background: #{color-no-variable(base-light)};
            border-radius: 9px;
        }

        a.photo-link
        {
            -webkit-box-flex: 1;
            flex: 1 0 auto;
            scroll-snap-align: start;
            width: 175px;
            height: 175px;

            img
            {
                width: 175px;
                height: 175px;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

@include responsive-down(md)
{
    .feed-wrapper
    {
        > .container
        {
            max-width: 100%;
        }
    }
}
@include responsive-up(md)
{
    .feed-wrapper
    {
        #instafeed
        {
            --scrollbar: 540px;

            &::-webkit-scrollbar-track {
                margin-left: calc((100vw - var(--scrollbar)) / 2);
                margin-right: calc((100vw - var(--scrollbar)) / 2);
            }

            a.photo-link
            {
                width: 250px;
                height: 250px;

                img
                {
                    width: 250px;
                    height: 250px;
                }
            }
        }
    }
}
@include responsive-up(lg)
{
    .feed-wrapper
    {
        .btn
        {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &::before
            {
                content:"";
                display: none;
            }

            span.icon
            {
                height: 30px;
                svg
                {
                    width:15px;
                    display: inline;
                    transition: all 320ms;
                }
            }

            &.more
            {
                span.more
                {
                    display: inline;
                }
                span.less
                {
                    display: none;
                }
            }
            &.less
            {
                span.less
                {
                    display: inline;
                }
                span.more
                {
                    display: none;
                }
                span
                {
                    svg
                    {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        #instafeed
        {
            max-height:460px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: auto;
            grid-gap: 15px;
            flex-wrap: wrap;
            -ms-scroll-snap-type: unset;
            scroll-snap-type: unset;
            overflow: hidden;
            -ms-overflow-style: unset;
            padding:0;
            transition: all 360ms;

            &::-webkit-scrollbar {
                width: unset;
            }

            a.photo-link
            {
                scroll-snap-align: unset;
                width: auto;
                height: auto;

                img
                {
                    width:221.25px;
                    height:221.25px;
                }
            }

            &.show-all
            {
                max-height:500vh;
            }
        }
    }
}
@include responsive-up(xl)
{
    .feed-wrapper
    {
        #instafeed
        {
            max-height:550px;

            a.photo-link
            {
                img
                {
                    width:266.25px;
                    height:266.25px;
                }
            }
        }
    }
}
