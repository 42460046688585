@use "~@latte-ui/css/src/api" as *;

.pswp
{
    .pswp__bg
    {
        background:#{color-no-variable(primary, .95)};
    }

    .pswp__scroll-wrap
    {
        .pswp__ui
        {
            .pswp__top-bar
            {
                background:transparent;
            }

            .pswp__button.pswp__button--arrow--left,
            .pswp__button.pswp__button--arrow--right
            {
                width:40px;
                height:40px;
                padding:0;
                margin:0;
                background-size:cover;
                background: transparent no-repeat center;
                top: 50%;
                transform: translateY(-50%);

                &::before
                {
                    background:transparent;
                }
            }
            .pswp__button.pswp__button--arrow--left
            {
                background-image: url(../icons/light/angle-left-white.svg);
            }
            .pswp__button.pswp__button--arrow--right
            {
                background-image: url(../icons/light/angle-right-white.svg);
            }
        }
    }
}

@include responsive-up(md)
{
    .pswp
    {
        .pswp__scroll-wrap
        {
            .pswp__ui
            {
                .pswp__button.pswp__button--arrow--left,
                .pswp__button.pswp__button--arrow--right
                {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
}
