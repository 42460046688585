.bg-lightgray{
    background-color: #e8e8e8 !important;

    .information {
        font-size: 20px;
    }
    p{
        color: gray;
    }
}
