@use "~@latte-ui/css/src/api" as *;

html
{
    height:100%;
    font-family: $font-family, sans-serif;
    font-size: 18px;
    font-weight:300;
    color:#{color-no-variable(base-light)};
    padding:0;
    margin:0;
}
body
{
    background: #{color-no-variable(primary)};
    font-family: $font-family, sans-serif;
    font-size: 18px;
    font-weight:300;
    color:#{color-no-variable(base-light)};
    display:flex;
    flex-direction: column;
    overflow-x: hidden;
    padding:0;
    margin:0;
}

ul
{
    margin:0;
    padding:0;
    list-style: none;

    li
    {
        position: relative;
        padding:6px 0;
        line-height: 1;

        &.blocks-gallery-item
        {
            padding:0;
        }
    }
}
ol
{
    margin:0;
    padding:0 0 0 17px;

    li
    {
        position: relative;
        padding: 5px 0 5px 3px;
        line-height: 1;
    }
}

img
{
    max-width:100% !important;
    height:auto;
}
.img-rounded
{
    img
    {
        border-radius: 50%;
    }
}

hr, .wp-block-separator
{
    width:100%;
    max-width:100%;
    border: solid 1px #{color-no-variable(base-light, .15)};
    margin: 0;
}

#main-content
{
    margin-top:150px;
}
#main-slider + #main-content
{
    margin-top:0;
}

.wrapper-top, .wrapper
{
    padding-top:#{gutter-get(7)} !important;

    &-small
    {
        padding-top:#{gutter-get(7)} !important;
    }
}
.wrapper-bottom, .wrapper
{
    padding-bottom:#{gutter-get(7)} !important;

    &-small
    {
        padding-bottom:#{gutter-get(7)} !important;
    }
}

.bg-white
{
    background-color:#{color-no-variable(base-light)} !important;
    color:#{color-no-variable(base-dark)} !important;
}
.bg-black
{
    background-color:#{color-no-variable(base-dark)} !important;
    color:#{color-no-variable(base-light)} !important;
}
.bg-primary
{
    background-color:#{color-no-variable(primary)} !important;
    color:#{color-no-variable(base-light)} !important;
}
.bg-secondary
{
    background-color:#{color-no-variable(secondary)} !important;
    color:#{color-no-variable(base-light)} !important;
}

.w-75
{
    width:100% !important;
}

@include responsive-up(md)
{
    #main-content
    {
        margin-top:200px;
    }

    .wrapper-top, .wrapper
    {
        padding-top:#{gutter-get(10)} !important;

        &-small
        {
            padding-top:#{gutter-get(9)} !important;
        }
    }
    .wrapper-bottom, .wrapper
    {
        padding-bottom:#{gutter-get(10)} !important;

        &-small
        {
            padding-bottom:#{gutter-get(9)} !important;
        }
    }

    .w-75
    {
        width:75% !important;
    }
}
@include responsive-up(lg)
{
    #main-content
    {
        margin-top:240px;
    }

    .wrapper-top, .wrapper
    {
        padding-top:#{gutter-get(11)} !important;
    }
    .wrapper-bottom, .wrapper
    {
        padding-bottom:#{gutter-get(11)} !important;
    }
}
