@use "~@latte-ui/css/src/api" as *;

#main-header
{
    width:100%;
    background:transparent;
    padding:0;
    pointer-events: none;
    z-index: 10;
    transition: all 360ms;
    position:fixed;
    top:0;
    left:0;
    right:0;

    .col-12 
    {
        position: relative;
    }

    &-logo
    {
        position: relative;
        z-index:1;
        transition: all 360ms;
        pointer-events: all;

        a
        {
            width:84px;
            height:auto;
            display: block;
            position: relative;
            transition: all 360ms;

            &::before
            {
                content:"";
                background:transparent;
                position: absolute;
                top:0;
                left:2px;
                right:2px;
                bottom:30px;
                z-index: 0;
                transition: all 360ms;
            }

            img
            {
                height:150px;
                transition: all 360ms;
                position: relative;
                z-index:1;
            }
        }

        &-statement
        {
            width: 100%;
            height:calc(100vh - 220px);
            opacity: 1;
            visibility: visible;
            transition: all 360ms;
            z-index: 9;
            position: absolute;
            top: 150px;
            bottom: 0;
            left: 0;
            right: 0;

            a
            {
                img
                {
                    height:42px;
                    transform: scale(1);
                    transition: all 360ms;
                }
            }

            &.hide
            {
                opacity: 0;
                visibility: hidden;

                a
                {
                    img
                    {
                        transform: scale(0);
                    }
                }
            }
            @at-root body:not(.home) &
            {
                display: none;
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &-toggle
    {
        pointer-events: all;

        &-icon, &-icon::before, &-icon::after
        {
            position: absolute;
            top:65px;
            left:15px;
            width: 23px;
            height: 2px;
            background: #{color-no-variable(base-light)};
            border-radius: 4px;
            text-align: center;
            transition-property: transform;
            transition-delay: .13s;
            transition-timing-function: cubic-bezier(.55,.055,.675,.19);
            transition-duration: .13s;
            cursor: pointer;
            outline:0;
            z-index:1;
            margin-bottom:-14px;
        }
        &-icon::before, &-icon::after
        {
            position: absolute;
        }

        &-icon::before
        {
            content: "";
            top: -7px;
            left:0;
            transition: top .12s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
        }
        &-icon::after
        {
            content: "";
            top: -14px;
            left:0;
            transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,opacity .1s linear;
        }

        &-input
        {
            position: absolute;
            top: 47px;
            left: 14px;
            width:25px;
            height:25px;
            margin: 0 -24px 0 0;
            z-index: 40;
            opacity:0;
            cursor: pointer;
            pointer-events: all;
        }
        &-input:checked~label
        {
            transition-delay: .22s;
            transition-timing-function: cubic-bezier(.215,.61,.355,1);
            transform: translate3d(0,-6px,0) rotate(-45deg);

            &::before
            {
                top: 0;
                transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,transform .13s cubic-bezier(.215,.61,.355,1) .25s;
                transform: rotate(-90deg);
            }
            &::after
            {
                top: 0;
                transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s linear .22s;
                opacity: 0;
            }

            + nav.nav-head
            {
                transform: translate3d(-50%, 0%, 0);
                opacity:1;
                visibility: visible;
            }
        }
    }

    nav.nav-head
    {
        width: 100vw;
        background-size:cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding:0;
        flex-flow: column;
        transition: 540ms var(--decelerationCurve);
        transform: translate3d(-50%, -100%, 0);
        z-index:0;
        opacity:0;
        visibility: hidden;
        pointer-events: all;

        &::before
        {
            content:"";
            width:0;
            height:0;
            display:none;
        }
        &::after
        {
            left:100%;
        }

        #menu-wrapper
        {
            width:100%;
            height:100vh;
            padding:150px 0 #{gutter-get(7)};
            z-index:1;
            overflow-y: scroll;
        }
    }

    &-menu
    {
        z-index: 1;

        .nav-link
        {
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background:transparent;
            font-family: $font-family-serif, serif;
            font-size:1.5rem;
            font-weight:300;
            font-style: italic;
            line-height: 1;
            color: #{color-no-variable(base-light)};
            text-transform: lowercase;
            padding:#{gutter-get(3)} #{gutter-get(8)};
            margin:#{gutter-get(3)} 0;
            transition: all 360ms;
            position: relative;

            &::before
            {
                content:"";
                width:105px;
                height:2px;
                background:#{color-no-variable(base-light)};
                position: absolute;
                top:-#{gutter-get(3)};
                margin:auto;
            }
            &:first-child
            {
                margin-top:0;

                &::before
                {
                    content: "";
                    display: none;
                }
            }
            &:last-child
            {
                margin-bottom:0;
            }

            &:hover, &:focus-within, &:active, &.is-active
            {
                background:#{color-no-variable(base-light)};
                color: #{color-no-variable(secondary)};
            }
        }
    }
    &-socials
    {
        .social
        {
            margin-top:#{gutter-get(7)};

            &-phone
            {
                width:40px;
                height:40px;
                background:#{color-no-variable(base-light)};
                border-radius: 50%;
                transition: all 360ms;

                svg {
                    display: block;
                    height:20px;
                    color:#{color-no-variable(secondary)};
                }

                &:hover, &:focus-within, &:active
                {
                    background:#{color-no-variable(base-light, .5)};
                }
            }
            &-twitter, &-facebook, &-instagram
            {
                margin:0 #{gutter-get(2)};

                svg {
                    display: block;
                    height:20px;
                    color:#{color-no-variable(base-light)};
                    transition: all 360ms;
                }

                &:hover, &:focus-within, &:active
                {
                    svg {
                        color:#{color-no-variable(base-light, .5)};
                    }
                }
            }
        }
    }

    @at-root body.scrolled & {
        &-logo
        {
            a
            {
                width:68px;

                &::before
                {
                    background:#{color-no-variable(secondary)};
                    bottom:16px;
                }

                img
                {
                    height:120px;
                }
            }
        }
    }
}
@include responsive-up(sm)
{
    #main-header
    {
        &-menu
        {
            .nav-link
            {
                padding:#{gutter-get(4)} #{gutter-get(8)};
                margin:#{gutter-get(4)} 0;

                &::before
                {
                    top:-#{gutter-get(4)};
                }
            }
        }
    }
}
@include responsive-up(md)
{
    #main-header
    {
        &-logo
        {
            a
            {
                width:112px;

                img
                {
                    height:200px;
                }
            }

            &-statement
            {
                height:calc(100vh - 275px);
                top: 200px;

                a
                {
                    img
                    {
                        height:56px;
                    }
                }
            }
        }

        nav.nav-head
        {
            #menu-wrapper
            {
                padding:200px 0 0;
            }
        }

        &-menu
        {
            .nav-link
            {
                margin:#{gutter-get(5)} 0;

                &::before
                {
                    top:-#{gutter-get(5)};
                }
            }
        }
        &-socials
        {
            .social
            {
                margin-top: #{gutter-get(9)};
            }
        }
    }
}
@include responsive-up(lg)
{
    #main-header
    {
        &-logo
        {
            a
            {
                width:135px;

                img
                {
                    height:240px;
                }
            }

            &-statement
            {
                height:calc(100vh - 300px);
                top: 240px;

                a
                {
                    img
                    {
                        height:68px;
                    }
                }
            }
        }

        nav.nav-head
        {
            #menu-wrapper
            {
                padding:240px 0 0;
            }
        }

        &-menu
        {
            .nav-link
            {
                font-size:1.7rem;
                margin:#{gutter-get(6)} 0;

                &::before
                {
                    top:-#{gutter-get(6)};
                }
            }
        }
    }
}
