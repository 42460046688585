@use "~@latte-ui/css/src/api" as *;

#main-content
{
    .bg-white, .bg-black, .bg-primary, .bg-secondary
    {
        &.wp-block-group
        {
            width: 100vw;
            margin-left: 50%;
            margin-top:#{gutter-get(7)};
            margin-bottom:#{gutter-get(7)};
            transform: translateX(-50%);
            padding:0;

            .wp-block-group__inner-container
            {
                width: 100%;
                margin:auto;
                padding:0 15px;
            }
            &:first-child
            {
                margin-top:-#{gutter-get(7)};
            }
            &:last-child
            {
                margin-bottom:-#{gutter-get(7)};

                @at-root .home &
                {
                    margin-bottom:0;
                }
            }
        }
    }

    .wp-block-gallery, .wpmf-gallerys
    {
        margin-top:#{gutter-get(7)};
        margin-bottom:15px;

        &:first-child
        {
            margin-top:0;

            &.full-height
            {
                margin-top:-#{gutter-get(7)};
            }
        }
        &:last-child
        {
            margin-bottom:0;

            &.full-height
            {
                margin-bottom:calc(-#{gutter-get(7)} - 15px);
            }
        }

        .blocks-gallery-grid
        {
            .blocks-gallery-item
            {
                width:100%;
                margin:0 0 15px;
                overflow: hidden;

                figure
                {
                    img
                    {
                        transform: scale(1);
                        transition: all 360ms;
                    }
                    figcaption
                    {
                        pointer-events: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width:100%;
                        height:100%;
                        background:#{color-no-variable(secondary, .77)};
                        font-family: $font-family-serif, serif;
                        font-size:25px;
                        font-style: italic;
                        line-height: 1.25;
                        padding:#{gutter-get(3)};
                        position: absolute;
                        top:0;
                        right:0;
                        bottom:0;
                        left:0;
                        opacity:0;
                        visibility: hidden;
                        transform: scale(1.1);
                        transition: all 360ms;
                    }
                }
            }
        }
    }
    .wpmf-gallerys
    {
        &:first-child
        {
            margin-top:-#{gutter-get(7)};
        }
        &:last-child
        {
            margin-bottom:calc(-#{gutter-get(7)} - 15px);

            @at-root .home &
            {
                margin-bottom:0;
            }
        }

        .gallery
        {
            .wpmf-gallery-item
            {
                width:100% !important;
                padding:0 !important;
                margin:0 0 15px !important;
                overflow: hidden;

                img
                {
                    transform: scale(1);
                    transition: all 360ms;
                }
                figcaption
                {
                    pointer-events: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width:100%;
                    height:100%;
                    font-family: $font-family-serif, serif;
                    font-size:25px;
                    font-style: italic;
                    line-height: 1.25;
                    padding:#{gutter-get(3)};
                    position: absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    transition: all 360ms;
                }
            }
        }
    }

    .wp-block-image, .wp-block-cover
    {
        margin-top:1rem;
        margin-bottom:1rem;

        &::before
        {
            content:"";
            display: none;
            position: relative;
        }

        &.full-width
        {
            width: 100vw;
            margin:#{gutter-get(7)} calc(50% - 50vw) #{gutter-get(7)} calc(50% - 50vw);

            figcaption
            {
                display: none;
            }

            &:first-child
            {
                margin-top:-#{gutter-get(7)};
            }
            &:last-child
            {
                margin-bottom:-#{gutter-get(7)};
            }
        }
    }
    .wp-block-cover.full-width
    {
        display: block;
        height:90px;
        min-height: unset;
        max-height: unset;
        background-size:cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        position: relative;
        z-index: 0;
    }

    .bg-white.wp-block-group + .wp-block-gallery, .bg-black.wp-block-group + .wp-block-gallery,
    .bg-primary.wp-block-group + .wp-block-gallery, .bg-secondary.wp-block-group + .wp-block-gallery
    {
        &.full-height
        {
            margin-top:-#{gutter-get(7)};
        }
    }
    .bg-white.wp-block-group + .wpmf-gallerys, .bg-black.wp-block-group + .wpmf-gallerys,
    .bg-primary.wp-block-group + .wpmf-gallerys, .bg-secondary.wp-block-group + .wpmf-gallerys
    {
        margin-top:-#{gutter-get(7)};
    }

    .bg-white.wp-block-group + .wp-block-image.full-width, .bg-black.wp-block-group + .wp-block-image.full-width,
    .bg-primary.wp-block-group + .wp-block-image.full-width, .bg-secondary.wp-block-group + .wp-block-image.full-width,
    .bg-white.wp-block-group + .wp-block-cover.full-width, .bg-black.wp-block-group + .wp-block-cover.full-width,
    .bg-primary.wp-block-group + .wp-block-cover.full-width, .bg-secondary.wp-block-group + .wp-block-cover.full-width
    {
        margin-top:-#{gutter-get(7)};
    }
    .wp-block-gallery + .wp-block-group.bg-white, .wp-block-gallery + .wp-block-group.bg-black,
    .wp-block-gallery + .wp-block-group.bg-primary, .wp-block-gallery + .wp-block-group.bg-secondary,
    .wpmf-gallerys + .wp-block-group.bg-white, .wpmf-gallerys + .wp-block-group.bg-black,
    .wpmf-gallerys + .wp-block-group.bg-primary, .wpmf-gallerys + .wp-block-group.bg-secondary
    {
        margin-top:calc(-#{gutter-get(7)} - 15px);
    }
    .wp-block-image.full-width + .bg-white.wp-block-group, .wp-block-image.full-width + .bg-black.wp-block-group,
    .wp-block-image.full-width + .bg-primary.wp-block-group, .wp-block-image.full-width + .bg-secondary.wp-block-group,
    .wp-block-cover.full-width + .bg-white.wp-block-group, .wp-block-cover.full-width + .bg-black.wp-block-group,
    .wp-block-cover.full-width + .bg-primary.wp-block-group, .wp-block-cover.full-width + .bg-secondary.wp-block-group
    {
        margin-top:-#{gutter-get(7)};
    }

    .wp-block-media-text
    {
        .wp-block-media-text__content
        {
            margin-top:1rem;
            padding:0;
        }

        &.has-media-on-the-right
        {
            .wp-block-media-text__content
            {
                margin-top:0;
                margin-bottom:1rem;
            }
        }
    }

    .wp-block-columns
    {
        .wp-block-column
        {
            margin-top:1.5rem;

            &:first-child
            {
                margin-top:0;
            }
        }
    }
}

@include responsive-up(sm)
{
    #main-content
    {
        .bg-white, .bg-black, .bg-primary, .bg-secondary
        {
            &.wp-block-group
            {
                .wp-block-group__inner-container
                {
                    max-width: 540px;
                }
            }
        }

        .wp-block-gallery, .wpmf-gallerys
        {
            margin-bottom:0;

            .blocks-gallery-grid
            {
                .blocks-gallery-item
                {
                    margin:0 15px 15px 0;
                }
            }

            &.columns-1
            {
                .blocks-gallery-grid
                {
                    .blocks-gallery-item
                    {
                        margin:0 0 15px;
                    }
                }
            }

            &.columns-2, &.columns-4, &.columns-5
            {
                .blocks-gallery-grid
                {
                    .blocks-gallery-item
                    {
                        width:calc(50% - 15px);
                    }
                }
            }
            &.columns-3
            {
                .blocks-gallery-grid
                {
                    .blocks-gallery-item
                    {
                        width:calc(33.33% - 15px);
                    }
                }
            }
            &:last-child
            {
                &.full-height
                {
                    margin-bottom: calc(-#{gutter-get(7)} - 30px);
                }
            }
        }
        .wpmf-gallerys
        {
            &:last-child
            {
                margin-bottom: calc(-#{gutter-get(7)} - 30px);

                @at-root .home &
                {
                    margin-bottom:0;
                }
            }

            .gallery
            {
                .wpmf-gallery-item
                {
                    width:calc(50% - 15px) !important;
                    margin:0 15px 15px 0 !important;
                }
            }
        }

        .wp-block-gallery + .wp-block-group.bg-white, .wp-block-gallery + .wp-block-group.bg-black,
        .wp-block-gallery + .wp-block-group.bg-primary, .wp-block-gallery + .wp-block-group.bg-secondary,
        .wpmf-gallerys + .wp-block-group.bg-white, .wpmf-gallerys + .wp-block-group.bg-black,
        .wpmf-gallerys + .wp-block-group.bg-primary, .wpmf-gallerys + .wp-block-group.bg-secondary
        {
            margin-top:calc(-#{gutter-get(7)} - 30px);
        }

        .wp-block-media-text
        {
            .wp-block-media-text__content
            {
                margin:0;
                padding:0 0 0 #{gutter-get(7)};
            }

            &.has-media-on-the-right
            {
                .wp-block-media-text__content
                {
                    margin:0;
                    padding:0 #{gutter-get(7)} 0 0;
                }
            }
        }

        .wp-block-columns
        {
            .wp-block-column
            {
                margin-top:0;

                &:first-child
                {
                    margin-top:0;
                }
            }
        }
    }
}
@include responsive-up(md)
{
    #main-content
    {
        .bg-white, .bg-black, .bg-primary, .bg-secondary
        {
            &.wp-block-group
            {
                margin-top:#{gutter-get(10)};
                margin-bottom:#{gutter-get(10)};

                .wp-block-group__inner-container
                {
                    max-width: 720px;
                }

                &:first-child
                {
                    margin-top:-#{gutter-get(10)};
                }
                &:last-child
                {
                    margin-bottom:-#{gutter-get(10)};

                    @at-root .home &
                    {
                        margin-bottom:0;
                    }
                }
            }
        }

        .wp-block-gallery, .wpmf-gallerys
        {
            margin-top:#{gutter-get(10)};
            margin-bottom:#{gutter-get(7)};

            &:first-child
            {
                &.full-height
                {
                    margin-top:-#{gutter-get(10)};
                }
            }
            &:last-child
            {
                &.full-height
                {
                    margin-bottom: calc(-#{gutter-get(10)} - 30px);
                }
            }

            &.columns-4
            {
                .blocks-gallery-grid
                {
                    .blocks-gallery-item
                    {
                        width:calc(25% - 15px);
                    }
                }
            }
            &.columns-5
            {
                .blocks-gallery-grid
                {
                    .blocks-gallery-item
                    {
                        width:calc(20% - 15px);
                    }
                }
            }

            .blocks-gallery-grid
            {
                .blocks-gallery-item
                {
                    figure
                    {
                        figcaption
                        {
                            font-size:30px;
                            line-height: 1.25;
                            padding:#{gutter-get(4)};
                        }
                    }
                }
            }
        }
        .wpmf-gallerys
        {
            &:first-child
            {
                margin-top:-#{gutter-get(10)};
            }
            &:last-child
            {
                margin-bottom: calc(-#{gutter-get(10)} - 30px);

                @at-root .home &
                {
                    margin-bottom:0;
                }
            }

            .gallery
            {
                .wpmf-gallery-item
                {
                    figcaption
                    {
                        font-size:30px;
                        line-height: 1.25;
                        padding:#{gutter-get(4)};
                    }
                }
            }
        }

        .wp-block-image, .wp-block-cover
        {
            margin-top:1.25rem;
            margin-bottom:1.25rem;

            &.full-width
            {
                margin-top:#{gutter-get(10)};
                margin-bottom:#{gutter-get(10)};

                &:first-child
                {
                    margin-top:-#{gutter-get(10)};
                }
                &:last-child
                {
                    margin-bottom:-#{gutter-get(10)};
                }
            }
        }
        .wp-block-cover.full-width
        {
            height:220px;
        }

        .bg-white.wp-block-group + .wp-block-gallery, .bg-black.wp-block-group + .wp-block-gallery,
        .bg-primary.wp-block-group + .wp-block-gallery, .bg-secondary.wp-block-group + .wp-block-gallery
        {
            &.full-height
            {
                margin-top:-#{gutter-get(10)};
            }
        }
        .bg-white.wp-block-group + .wpmf-gallerys, .bg-black.wp-block-group + .wpmf-gallerys,
        .bg-primary.wp-block-group + .wpmf-gallerys, .bg-secondary.wp-block-group + .wpmf-gallerys
        {
            margin-top:-#{gutter-get(10)};
        }

        .bg-white.wp-block-group + .wp-block-image.full-width, .bg-black.wp-block-group + .wp-block-image.full-width,
        .bg-primary.wp-block-group + .wp-block-image.full-width, .bg-secondary.wp-block-group + .wp-block-image.full-width,
        .bg-white.wp-block-group + .wp-block-cover.full-width, .bg-black.wp-block-group + .wp-block-cover.full-width,
        .bg-primary.wp-block-group + .wp-block-cover.full-width, .bg-secondary.wp-block-group + .wp-block-cover.full-width
        {
            margin-top:-#{gutter-get(10)};
        }
        .wp-block-gallery + .wp-block-group.bg-white, .wp-block-gallery + .wp-block-group.bg-black,
        .wp-block-gallery + .wp-block-group.bg-primary, .wp-block-gallery + .wp-block-group.bg-secondary,
        .wpmf-gallerys + .wp-block-group.bg-white, .wpmf-gallerys + .wp-block-group.bg-black,
        .wpmf-gallerys + .wp-block-group.bg-primary, .wpmf-gallerys + .wp-block-group.bg-secondary
        {
            margin-top:calc(-#{gutter-get(10)} - 30px);
        }
        .wp-block-image.full-width + .bg-white.wp-block-group, .wp-block-image.full-width + .bg-black.wp-block-group,
        .wp-block-image.full-width + .bg-primary.wp-block-group, .wp-block-image.full-width + .bg-secondary.wp-block-group,
        .wp-block-cover.full-width + .bg-white.wp-block-group, .wp-block-cover.full-width + .bg-black.wp-block-group,
        .wp-block-cover.full-width + .bg-primary.wp-block-group, .wp-block-cover.full-width + .bg-secondary.wp-block-group
        {
            margin-top:-#{gutter-get(10)};
        }
    }
}
@include responsive-up(lg)
{
    #main-content
    {
        .bg-white, .bg-black, .bg-primary, .bg-secondary
        {
            &.wp-block-group
            {
                margin-top:#{gutter-get(11)};
                margin-bottom:#{gutter-get(11)};

                .wp-block-group__inner-container
                {
                    max-width: 960px;
                }

                &:first-child
                {
                    margin-top:-#{gutter-get(11)};
                }
                &:last-child
                {
                    margin-bottom:-#{gutter-get(11)};

                    @at-root .home &
                    {
                        margin-bottom:0;
                    }
                }
            }
        }

        .wp-block-gallery, .wpmf-gallerys
        {
            margin-top:#{gutter-get(11)};
            margin-bottom:#{gutter-get(10)};

            @at-root .home &
            {
                margin-bottom:0;
            }

            &:first-child
            {
                &.full-height
                {
                    margin-top: -#{gutter-get(11)};
                }
            }
            &:last-child
            {
                &.full-height
                {
                    margin-bottom: calc(-#{gutter-get(11)} - 30px);
                }
            }

            .blocks-gallery-grid
            {
                .blocks-gallery-item
                {
                    figure
                    {
                        figcaption
                        {
                            font-size:37px;
                            line-height: 1.5;
                            padding:#{gutter-get(7)};
                        }

                        &:hover, &:focus-within, &:active
                        {
                            img
                            {
                                transform: scale(1.1);
                            }
                            figcaption
                            {
                                opacity:1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .wpmf-gallerys
        {
            &:first-child
            {
                margin-top:-#{gutter-get(11)};
            }
            &:last-child
            {
                margin-bottom: calc(-#{gutter-get(11)} - 30px);

                @at-root .home &
                {
                    margin-bottom:0;
                }
            }

            .gallery
            {
                .wpmf-gallery-item
                {
                    figcaption
                    {
                        font-size:37px;
                        line-height: 1.5;
                        padding:#{gutter-get(7)};
                    }

                    &:hover, &:focus-within, &:active
                    {
                        img
                        {
                            transform: scale(1.1);
                        }
                        figcaption
                        {
                            opacity:1;
                            visibility: visible;
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        .wp-block-image, .wp-block-cover
        {
            margin-top:1.5rem;
            margin-bottom:1.5rem;

            &.full-width
            {
                margin-top:#{gutter-get(11)};
                margin-bottom:#{gutter-get(11)};

                &:first-child
                {
                    margin-top:-#{gutter-get(11)};
                }
                &:last-child
                {
                    margin-bottom:-#{gutter-get(11)};
                }
            }
        }
        .wp-block-cover.full-width
        {
            height:275px;
        }

        .bg-white.wp-block-group + .wp-block-gallery, .bg-black.wp-block-group + .wp-block-gallery,
        .bg-primary.wp-block-group + .wp-block-gallery, .bg-secondary.wp-block-group + .wp-block-gallery
        {
            &.full-height
            {
                margin-top:-#{gutter-get(11)};
            }
        }
        .bg-white.wp-block-group + .wpmf-gallerys, .bg-black.wp-block-group + .wpmf-gallerys,
        .bg-primary.wp-block-group + .wpmf-gallerys, .bg-secondary.wp-block-group + .wpmf-gallerys
        {
            margin-top:-#{gutter-get(11)};
        }

        .bg-white.wp-block-group + .wp-block-image.full-width, .bg-black.wp-block-group + .wp-block-image.full-width,
        .bg-primary.wp-block-group + .wp-block-image.full-width, .bg-secondary.wp-block-group + .wp-block-image.full-width,
        .bg-white.wp-block-group + .wp-block-cover.full-width, .bg-black.wp-block-group + .wp-block-cover.full-width,
        .bg-primary.wp-block-group + .wp-block-cover.full-width, .bg-secondary.wp-block-group + .wp-block-cover.full-width
        {
            margin-top:-#{gutter-get(11)};
        }
        .wp-block-gallery + .wp-block-group.bg-white, .wp-block-gallery + .wp-block-group.bg-black,
        .wp-block-gallery + .wp-block-group.bg-primary, .wp-block-gallery + .wp-block-group.bg-secondary,
        .wpmf-gallerys + .wp-block-group.bg-white, .wpmf-gallerys + .wp-block-group.bg-black,
        .wpmf-gallerys + .wp-block-group.bg-primary, .wpmf-gallerys + .wp-block-group.bg-secondary
        {
            margin-top:calc(-#{gutter-get(11)} - 30px);
        }
        .wp-block-image.full-width + .bg-white.wp-block-group, .wp-block-image.full-width + .bg-black.wp-block-group,
        .wp-block-image.full-width + .bg-primary.wp-block-group, .wp-block-image.full-width + .bg-secondary.wp-block-group,
        .wp-block-cover.full-width + .bg-white.wp-block-group, .wp-block-cover.full-width + .bg-black.wp-block-group,
        .wp-block-cover.full-width + .bg-primary.wp-block-group, .wp-block-cover.full-width + .bg-secondary.wp-block-group
        {
            margin-top:-#{gutter-get(11)};
        }

        .wp-block-media-text
        {
            .wp-block-media-text__content
            {
                padding:0 0 0 #{gutter-get(10)};
            }

            &.has-media-on-the-right
            {
                .wp-block-media-text__content
                {
                    padding:0 #{gutter-get(10)} 0 0;
                }
            }
        }
    }
}
@include responsive-up(xl)
{
    #main-content
    {
        .bg-white, .bg-black, .bg-primary, .bg-secondary
        {
            &.wp-block-group
            {
                .wp-block-group__inner-container
                {
                    max-width: 1140px;
                }
            }
        }

        .wp-block-cover.full-width
        {
            height:350px;
        }
    }
}
